/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import dynamic from 'next/dynamic';
import Skeleton from '@material-ui/lab/Skeleton';

const Item = dynamic(() => import('@plugin_optionitem/ConfigurableOption/Item'), { ssr: true });
const Footer = dynamic(() => import('@plugin_optionitem/components/Footer'), { ssr: true });
const LinkedSkuList = dynamic(() => import('@core_modules/product/pages/default/components/LinkedSkuList'), { ssr: true });
const SizeGuidePopup = dynamic(() => import('@core_modules/product/pages/default/components/SizeGuidePopup'), { ssr: true });
const CountDown = dynamic(() => import('@core_modules/product/pages/default/components/CountDown'), { ssr: false });

const ProductWrapperAction = (props) => {
    const {
        SIZE_GUIDE_LABEL, sku, data, loading, disabled, showQty = true, handleAddToCart, qty, setQty,
        t, options, selectConfigurable, showAddToCart = true, isGrid = true,
        showSwatches = true, customPos = false, storeConfig, isPdp, handleSizeGuide,
        sizeGuideModalOpen, handleCloseSizeGuideModal, dataCmsSizeGuide, loadingCmsSizeGuide,
        errorCmsSizeGuide, dataCmsCountdown, ...other
    } = props;
    const updatedOptions = customPos ? [...options].sort((a, b) => a.options.position - b.options.position) : options;

    const sizeGuideExist = data?.more_info?.length > 0 && data?.more_info.some((item) => item.label === SIZE_GUIDE_LABEL);

    const sizeGuideContent = dataCmsSizeGuide?.cmsBlocks?.items?.length > 0
        ? dataCmsSizeGuide.cmsBlocks.items[0].content : null;

    return (
        <>
            {showSwatches && updatedOptions.map((item, index) => (
                <>
                    <div className={`wrapper${item.options.label}`} key={index}>
                        <div className={`label${item.options.label}`}>
                            {`${item.options.label}`}
                            {item.options.attribute_code === 'size' && ' (US) '}
                            :
                        </div>
                        {sizeGuideExist && item.options.attribute_code === 'size' && (
                            <a className="sizing-information" onClick={handleSizeGuide}>
                                <img src="/assets/img/product/ruler.svg" alt="ruler" />
                                <span>{t('common:product:sizingInformation')}</span>
                            </a>
                        )}
                        <Item
                            key={index}
                            option={item.options}
                            selected={selectConfigurable}
                            value={item.value}
                            isGrid={isGrid}
                            {...other}
                            className={`product-configurableOption-${item.options.label}`}
                        />
                    </div>
                </>
            ))}
            <Footer
                loading={loading}
                disabled={disabled}
                showQty={showQty}
                handleAddToCart={handleAddToCart}
                qty={qty}
                setQty={setQty}
                t={t}
                showAddToCart={showAddToCart}
                {...other}
            />
            <SizeGuidePopup
                t={t}
                open={sizeGuideModalOpen}
                handleClose={handleCloseSizeGuideModal}
                content={sizeGuideContent}
                loading={loadingCmsSizeGuide}
                error={errorCmsSizeGuide}
                storeConfig={storeConfig}
            />
        </>
    );
};

const ConfigurableView = (props) => {
    const {
        sku, data, storeConfig, isPdp, countDownData, loadingCmsCountdown,
    } = props;
    const productLinked = data.product_linked;
    const showLinkedSkuList = isPdp && productLinked?.length > 0;
    let elementCountdown;
    let isCountDown = false;
    if (countDownData) {
        const mixedContents = countDownData.replace('[/mgz_pagebuilder]', '[mgz_pagebuilder]').split('[mgz_pagebuilder]');
        const removeIdentifier = JSON.parse(mixedContents[1]);
        elementCountdown = removeIdentifier?.elements[0]?.elements[0]?.elements[0];
        isCountDown = elementCountdown.type === 'countdown';
    }
    const isCommingSoon = data?.coming_soon === 1;

    if (loadingCmsCountdown) {
        return (
            <>
                <Skeleton animation="wave" variant="text" width="100%" height={30} />
                <Skeleton animation="wave" variant="text" width="100%" height={30} />
                <Skeleton animation="wave" variant="text" width="100%" height={30} />
            </>
        );
    }

    return (
        <>
            {showLinkedSkuList ? (
                <LinkedSkuList
                    linkedSkuList={productLinked}
                    currentSku={sku}
                    storeConfig={storeConfig}
                />
            ) : null}
            {countDownData && isCountDown && isPdp && isCommingSoon ? (
                <CountDown content={elementCountdown} completeProps={props} CompleteContent={ProductWrapperAction} />
            ) : !countDownData && isCommingSoon && isPdp ? (
                <div style={{
                    textAlign: 'center',
                    fontSize: 18,
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                }}
                >
                    comming soon product
                </div>
            ) : <ProductWrapperAction {...props} />}

        </>
    );
};

export default ConfigurableView;
